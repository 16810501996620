import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"
import { getLocalizedPage } from "@/utils/localize-fetch"
import { localizePath } from "@/utils/localize"

import { useCookies } from "react-cookie"

//import { useOnClickOutside } from "@/utils/hooks"

const LocaleSwitch = ({ pageContext }) => {
  const isMounted = useRef(false)
  const select = useRef()
  const [cookies, setCookie] = useCookies()
  const [locale, setLocale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale
  )
  //const [showing, setShowing] = useState(false)

  const handleLocaleChange = async selectedLocale => {
    setCookie("GATSBY_LOCALE", selectedLocale, {
      path: "/",
      secure: process.env.NODE_ENV,
      sameSite: "Strict",
    })
    setLocale(selectedLocale)
  }
  const handleLocaleChangeRef = useRef(handleLocaleChange)

  //useOnClickOutside(select, () => setShowing(false))

  useEffect(() => {
    // Set the requested locale when no cookie locale is found
    if (!cookies.GATSBY_LOCALE) {
      handleLocaleChangeRef.current(pageContext.defaultLocale)
    }

    const changeLocale = async () => {
      //setShowing(false)
      if (
        !isMounted.current &&
        cookies.GATSBY_LOCALE &&
        cookies.GATSBY_LOCALE !== pageContext.locale
      ) {
        // Redirect to locale page if locale mismatch
        const localePage = await getLocalizedPage(
          cookies.GATSBY_LOCALE,
          pageContext
        )
        navigate(localizePath({ ...pageContext, ...localePage }))
      }
    }

    changeLocale()

    return () => {
      isMounted.current = true
    }
  }, [locale, pageContext, cookies.GATSBY_LOCALE])

  return (
    <div ref={select} className="right-sec-nav d-flex align-items-center">
      <div className="lang-menu-wr">
        {pageContext.localizedPaths?.map(({ href, locale }) => {
          return (
            <Link
              to={href}
              key={locale}
              onClick={() => handleLocaleChange(locale)}
              role="option"
              className="btn lang-toggle text-uppercase"
            >
              {locale}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default LocaleSwitch

import { fetchAPI } from "./api"

export async function getLocalizedPage(targetLocale, pageContext) {
  const localization = pageContext.localizations.find(
    localization => localization.locale === targetLocale
  )
  if(pageContext.id.startsWith('Page')) {
    const localePage = await fetchAPI(`/pages/${localization.id}`)
    return localePage
  } 
  if(pageContext.id.startsWith('Actor')) {
    const localePage = await fetchAPI(`/actors/${localization.id}`)
    return localePage
  }
}

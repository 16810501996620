import React from "react"



const Footer = ({ footer, copyright }) => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="soc-icons-wrap">
          {footer.map((footerLink) => (
            <a href={footerLink.url} className="soc-icon" key={footerLink.id} target={footerLink.newTab ? '_blank' : '_self'}>
              <i className={footerLink.icon} aria-hidden="true"></i>
            </a>
          ))}
        </div>
        <p className="copyright">{copyright}</p>
      </div>
    </footer>
  )
}


export default Footer

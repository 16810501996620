/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({ seo, global }) {

  const metaTitle = (seo && seo.metaTitle) ? seo.metaTitle : global.seo.metaTitle;
  const metaDescription = (seo && seo.metaDescription) ? seo.metaDescription : global.seo.metaDescription;
  const keywords = (seo && seo.keywords) ? seo.keywords : global.seo.keywords;
  const mediaAlt = (seo && seo.mediaAlt) ? seo.mediaAlt : global.seo.mediaAlt;
  const media = (seo && seo.media) ? seo.media : global.seo.media;
  const preventIndex = (seo && seo.preventIndex == null) ? seo.preventIndex : global.seo.preventIndex;

  const getMetaTags = () => {
    const tags = []

    tags.push(
      {
        property: "og:title",
        content: metaTitle,
      },
      {
        name: "twitter:title",
        content: metaTitle,
      }
    )
    
    tags.push(
      {
        name: "description",
        content: metaDescription,
      },
      {
        property: "og:description",
        content: metaDescription,
      },
      {
        name: "twitter:description",
        content: metaDescription,
      }
    )
    
    if (media) {
      const imageUrl = process.env.GATSBY_STRAPI_URL
        ? `${media.localFile.publicURL}`
        : `http://localhost:8000${media.localFile.publicURL}`

      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      )
    }
    if (keywords) {
      tags.push({
        property: "keywords",
        content: keywords,
      })
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const metaTags = getMetaTags()

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={metaTitle ? `%s | ${metaTitle}` : null}
      meta={preventIndex ? [] : metaTags}
      link={[
        {
          rel: "icon",
          href: global.favicon.localFile.publicURL,
        },
      ]}
    />
  )
}


export default Seo

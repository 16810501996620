import * as React from "react"
import LocaleSwitch from "./locale-switch"
import { localizePath } from "@/utils/localize"

import { Link } from "gatsby"

const Header = ({ siteTitle, navbar, logo, pageContext, tables, populateSearchResult }) => {
	const [showMenu, setShowMenu] = React.useState(false);

	const [showSearch, setShowSearch] = React.useState(false);
	const [searchField, setSearchField] = React.useState('');

	const barClick = () => {
		setShowMenu(true);
	}

	const hideClick = () => {
		setShowMenu(false);
	}

	const logoClick = () => {
		populateSearchResult([]);
	}

	const searchOpen = () => {
		setShowSearch(true);
	}

	const onSearchSubmit = (event) => {
		event.preventDefault();
		console.log("Submit hit");
	}

	const nameFilter = (actor) => {
        if(searchField == "") {
            return false;
        }

		if(actor.name.toLowerCase().includes(searchField.toLowerCase()) 
			|| actor.lastname.toLowerCase().includes(searchField.toLowerCase()) 
			|| actor.stagename.toLowerCase().includes(searchField.toLowerCase())) {
			return true;
		}

		return false;
        
    }

	const onSearchChange = (event) => {
		setSearchField(event.target.value);

		const found = tables.allStrapiActor.nodes.filter(nameFilter)

        populateSearchResult(found);
	}

	return (
		<header className="header transparent">
			<div className="container">
				<div className="header-inner">
					<Link onClick={logoClick} to={localizePath({ ...pageContext, isPreview: false, slug: "" })}>
						<img src={logo.localFile.publicURL} />
					</Link>
					<div className={`navigation-wr ${showMenu ? "active" : ""}`}>
						<nav className="navigation">
							<ul>
								{navbar.map(navLink => (
									<li key={navLink.id}
										className="menu-item-has-children">
										<Link to={localizePath({
												...pageContext,
												isPreview: false,
												// Remove the '/'
												slug: navLink.url.slice(1),
											})}>
											{navLink.title}
										</Link>
									</li>
								))}
							</ul>
						</nav>
						<div className="right-sec-nav d-flex align-items-center">
							<div>
							<form className="search-wr" onSubmit={onSearchSubmit}>
								<input type="text" id="search" name="search" className={`${showSearch ? "active" : ""}`} value={searchField} onChange={onSearchChange}  />
								<i onClick={searchOpen} className="icon ion-ios-search"></i>
								<input type="submit" className="submit" value="" />
							</form>
							</div>
							{pageContext.localizations && (
								<div className="lang-menu-wr">
									<LocaleSwitch pageContext={pageContext} />
								</div>
							)}
						</div>
						
						<i onClick={hideClick} className="fa fa-times" aria-hidden="true"></i>
						
					</div>	
					<i onClick={barClick} className="fa fa-bars" aria-hidden="true"></i>
				</div>
			</div>
		</header>
	)
}

export default Header

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"


import "../css/font-awesome.css"
import "../css/ionicons.min.css"

import "../fonts/main-fonts.css"

import "../slick/slick.css"
import "../slick/slick-theme.css"

import "../scss/bootstrap.scss"
import "../scss/style.scss"

const Layout = ({ children, global, pageContext, tables, populateSearchResult, searchResult, searchRef}) => {
  const { navbar, footbarLinks, logo, copyright } = global

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} navbar={navbar} logo={logo} pageContext={pageContext} 
        tables={tables} populateSearchResult={populateSearchResult} searchResult={searchResult} searchRef={searchRef} />
      <main>{children}</main>
      <Footer footer={footbarLinks} copyright={copyright} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
